import { Box, Stack, } from "@mui/material";
import { useSendMail } from "./hooks/sendMail";
import { useTranslation } from "react-i18next";
import BaseForm from "../../formik/BaseForm"
import SubmitButton from "../../formik/SubmitButton"
import { useContactUsValidation } from "../../validation/contactUsValidation";
import CustomFormikField from "../../formik/CustomFormikField";
const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: ""
}
export const ContactUsForm = () => {
    const {contactUsValidationSchema} = useContactUsValidation()
    const { t } = useTranslation("contactUs");
    const {sendMail, loading} = useSendMail()
    return (
        <Box py={8}>
            <BaseForm
                onSubmit={sendMail}
                initialValues={initialValues}
                validationSchema={contactUsValidationSchema}
            >
                <Stack spacing={2}>
                    <CustomFormikField name="name" label={t("contactUs.formLabel.name")} type="text" />
                    <CustomFormikField name="phone" label={t("contactUs.formLabel.phone")} type="tel" />
                    <CustomFormikField name="email" label={t("contactUs.formLabel.email")} type="email" />
                    <CustomFormikField name="message" label={t("contactUs.formLabel.message")} rows={6} type="text" />
                </Stack>
                <SubmitButton type="submit" disabled={loading} >
                    {t("contactUs.formBtn")}
                </SubmitButton>
            </BaseForm>
        </Box>
    );
}
