import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
const LinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: '4px',
    '&:hover': {
        textDecoration: 'underline',
    }
}));
function ReadPrivacyConditions() {
    const { t } = useTranslation("add-product")
    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary">
                {t("acceptPrivacy.title")}{" "}
                <LinkStyled
                    to="/privacy-policy"

                >
                    {
                        t("acceptPrivacy.privacy")
                    }
                </LinkStyled>
                {' '}{t("acceptPrivacy.and")}{' '}
                <LinkStyled
                    to="/terms-of-service"
                >
                    {t("acceptPrivacy.conditions")}
                </LinkStyled>
            </Typography>
        </Box>
    )
}

export default ReadPrivacyConditions