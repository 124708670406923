// Search.jsx
import { IconButton, Stack } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { setSearchByName } from '../../redux/slices/filterSlice';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../helper/debounce';

// Styled input component
const StyledInput = styled('input')({
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
});

export const Search = () => {
    const { t } = useTranslation("BrowseListing");
    const currentSearch = useSelector((state) => state.filter.filterKeys)?.name || "";
    const [search, setSearch] = useState(currentSearch);
    const dispatch = useDispatch();
    const theme = useTheme();
    const debouncedSearchRef = useRef(null);

    // Create the debounced function and store it in a ref
    useEffect(() => {
        debouncedSearchRef.current = debounce((value) => {
            dispatch(setSearchByName(value));
        }, 500);
        setSearch(currentSearch);
    }, [dispatch, currentSearch]);

    // Handle input change
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearchRef.current(value); // Call the debounced function
    };

    // Clear search input
    const clearSearch = () => {
        setSearch("");
        dispatch(setSearchByName(""));
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: "60px",
                backgroundColor: "rgb(247, 247, 247)",
                borderRadius: "20px",
                px: 3,
                width: ["100%", "50%"],
                margin: "auto",
                position: "relative",
            }}
        >
            <IconButton
                aria-label="searchIcon"
                sx={{ color: theme.palette.text.primary }}
            >
                <SearchIcon aria-label="searchIcon" />
            </IconButton>

            <StyledInput
                value={search}
                type="text"
                placeholder={t("search")}
                onChange={handleInputChange}
            />

            {search && (
                <IconButton
                    aria-label="clearSearch"
                    onClick={clearSearch}
                    sx={{ color: theme.palette.text.primary }}
                >
                    <ClearIcon />
                </IconButton>
            )}

            <TuneIcon style={{ color: theme.palette.primary.main }} />
        </Stack>
    );
};