import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import useSendTokenToContact from "./SendToken";
import { useState } from "react";
export const useSignupSubmit = () => {
    const [loading, setLoading] = useState(false);
    const { showAlert } = useShowAlert();
    const { sendTokenToEmail, sendTokenToPhoneNumber } = useSendTokenToContact();
    const signupSubmit = (values) => {
        setLoading(true);
        // Determine if the identifier is an email or phone number
        const isEmail = values.identifier.includes("@") || values.identifier.includes(".");
        // Prepare the payload
        const payload = {
            password: values.password,
            ...(values.username && { username: values.username }),
            [isEmail ? "email" : "phoneNumber"]: isEmail ? values.identifier : `${values.countryCode}${values.identifier}`
        };

        axiosInstance
            .post("/user/signup", payload)
            .then(() => {
                if (isEmail) {
                    sendTokenToEmail(values.identifier);
                } else {
                    sendTokenToPhoneNumber(`${values.countryCode}${values.identifier}`);
                }
            })
            .catch((err) => {
                if (err) {
                    showAlert("error", err.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
    };

    return { signupSubmit, loading };
};
