import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import { fetchUser } from "../redux/slices/AuthUser";
import { uploadImage } from "../helper/UploadImage";
import axiosInstance from "../axios/axiosInstance"; 

export const useImageUpload = (userId) => {
  const [successUpload, setSuccessUpload] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const setAlert = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  const handleChangeImage = async (e) => {
    setUploading(true);
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      setSuccessUpload(false);
      setUploading(false);
      setAlert("error", "Please choose the correct type of file");
      return;
    }

    try {
      const imageUrl = await uploadImage(file);
      setUploadedImage(imageUrl);

      await axiosInstance.put(`/user/${userId}`, { profilePicture: imageUrl });

      dispatch(fetchUser());

      setSuccessUpload(true);
      setAlert("success", "Image uploaded successfully");
    } catch (error) {
      setAlert("error", "Image upload failed");
    } finally {
      setUploading(false);
    }
  };

  return {
    successUpload,
    uploadedImage,
    uploading,
    handleChangeImage
  };
}; 