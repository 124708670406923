export const getTranslatedArray = (t, key) => {
  try {
    const result = t(key, { returnObjects: true });
    return Array.isArray(result) ? result : [];
  } catch (error) {
    return [];
  }
};

export const getTranslatedObject = (t, key) => {
  try {
    const result = t(key, { returnObjects: true });
    return typeof result === 'object' && result !== null ? result : {};
  } catch (error) {
    return {};
  }
}; 