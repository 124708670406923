import InfiniteScrollList from "../general/InfiniteScrollLis";
import { ProductItem } from "../general/ProductItem";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";

export const ProductsList = ({ apiProductsUrl }) => {
  const { t } = useTranslation("BrowseListing");

  const fetchProducts = async (page, limit) => {
    const response = await axiosInstance.get(`${apiProductsUrl}?page=${page}&limit=${limit}`);
    return response.data.products;
  };

  const ProductItems = ({ items }) => {
    return (
      <Grid container spacing={2}>
        {items.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4} >
            <ProductItem product={product} />
          </Grid>
        ))}
      </Grid>
    );
  }
  const LoadingProducts = () => {
    return (
      <Grid container spacing={2}>
        {[...Array(6)].map((_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} >
            <SkeletonLoadingProducts />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <InfiniteScrollList
      fetchData={fetchProducts}
      loadingText={t("loadingText")}
      noProductsText={t("noProducts")}
      LoadingComponet={LoadingProducts}
      RenderComponent={ProductItems}
    />
  );
};
