import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export const AuthLinks = () => {
    const { t } = useTranslation("header");
    return (<>
        <Button
            sx={{ my: 2, px: 0, color: "secondary.main", display: "block", textTransform: "capitalize", fontSize: "16px", fontWeight: "bold", textAlign: "center" }}
            component={Link}
            to={`/signup`}
        >
            {t("signup")}
        </Button>
        <Button
            sx={{ my: 2, px: 0, color: "secondary.main", display: "block", textTransform: "capitalize", fontSize: "16px", fontWeight: "bold", textAlign: "center" }}
            component={Link}
            to={`/login`}
        >
            {t("login")}
        </Button>

    </>
    )
}
