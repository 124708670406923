import { Avatar, Box, Paper, Stack, TextField, Typography, IconButton } from '@mui/material';
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";

import { Link } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CreateIcon from '@mui/icons-material/Create';
import { useImageUpload } from "../../hooks/useImageUpload";

const UploadingOverlay = () => (
  <Box
    sx={{
      position: "absolute",
      inset: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
      zIndex: 3
    }}
  >
    <Typography color="white">Uploading...</Typography>
  </Box>
);

const AvatarImage = ({ profilePicture, displayName, successUpload, uploadedImage }) => (
  <Avatar
    sx={{
      width: 100,
      height: 100,
      '& img': {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      }
    }}
  >
    {profilePicture ? (
      <img
        src={successUpload ? uploadedImage : profilePicture}
        alt={displayName || "user"}
        loading="lazy"
      />
    ) : (
      displayName?.[0].toUpperCase() || ""
    )}
  </Avatar>
);

const EditIcon = ({ userId }) => (
  <IconButton
    component={Link}
    to={`/account-info/${userId}`}
    sx={{
      bgcolor: 'primary.main',
      width: 30,
      height: 30,
      '&:hover': {
        bgcolor: 'primary.dark',
      }
    }}
  >
    <CreateIcon sx={{ color: 'white', fontSize: 20 }} />
  </IconButton>
);

export const UserAvatar = () => {
  const authUser = useSelector(getUser);
  const {
    successUpload,
    uploadedImage,
    uploading,
    handleChangeImage
  } = useImageUpload(authUser?.id);

  return (
    <Stack
      spacing={{ xs: 2, md: 4 }}
      sx={{
        alignItems: "center",
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        padding: { xs: 2, md: 3 },
      }}
    >
      <Box>
        <Paper
          elevation={5}
          sx={{
            borderRadius: '50%',
            width: 100,
            height: 100,
            position: 'relative',
          }}
        >
          {uploading && <UploadingOverlay />}
          <AvatarImage
            profilePicture={authUser?.user?.profilePicture}
            displayName={authUser?.user?.displayName}
            successUpload={successUpload}
            uploadedImage={uploadedImage}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 11,
              right: 15,
            }}
          >
            <TextField
              type="file"
              sx={{ display: 'none' }}
              id="avatar"
              onChange={handleChangeImage}
            />
            <IconButton
              component="label"
              htmlFor="avatar"
              sx={{
                bgcolor: 'primary.main',
                width: 30,
                height: 30,
                border: 2,
                borderColor: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <AddAPhotoIcon sx={{ fontSize: 15, color: 'white' }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>

      <Stack
        spacing={{ xs: 1, md: 2 }}
        direction="row"
        alignItems="center"
        sx={{
          width: { xs: '100%', md: 'auto' },
          justifyContent: { xs: 'center', md: 'flex-start' },
        }}
      >
        <Stack
          spacing={1}
          sx={{
            pl: 1,
            justifyContent: "center"
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: '18px', sm: '20px', md: '24px' },
              fontWeight: 'bold',
              margin: 0
            }}
          >
            {authUser?.user?.displayName}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              wordBreak: 'break-word',
              margin: 0
            }}
          >
            {authUser?.user?.email || authUser?.user?.phoneNumber}
          </Typography>
        </Stack>
        <EditIcon userId={authUser?.id} />
      </Stack>
    </Stack>
  );
};
