import { useNavigate } from "react-router-dom";

const useNavigateBasedOnPath = () => {
  const navigate = useNavigate();

  // Define restricted paths that should redirect to home
  const RESTRICTED_PATHS = [
    '/verification',
    '/login',
    '/signup',
    '/reset-password/new-password'
  ];

  const navigateBasedOnPath = () => {
    const prevPath = JSON.parse(localStorage.getItem("pathHistory"));

    // Check if prevPath is empty or starts with any restricted path
    const shouldNavigateHome = !prevPath?.length || 
      RESTRICTED_PATHS.some(path => prevPath[0]?.startsWith(path));

    navigate(shouldNavigateHome ? '/' : prevPath[0]);
  };

  return navigateBasedOnPath;
};

export default useNavigateBasedOnPath;
