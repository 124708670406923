import { useState, useEffect, } from "react";
import { Container, Grid, ToggleButtonGroup, ToggleButton, Button, Box, Stack } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WindowIcon from "@mui/icons-material/Window";
import { useSelector } from "react-redux";
import ProductsListContainer from "../components/browse-listing/ProductsListContainer";
import FilterContainer from "../components/browse-listing/filtering/FilterContainer";
import FilterContainerOnMobile from "../components/browse-listing/filtering/FilterContainerOnMobile";
import { Search } from "../components/browse-listing/Search";
import CatigoryListOnMobile from "../components/browse-listing/filtering/CategoryListOnMobile";
import MapComponent from "../components/browse-listing/ShowProductsOnMap/MapComponent";
import { SelectedFilter } from "../components/browse-listing/SelectedFilter";
import { useTranslation } from "react-i18next";
export const BrowseListing = () => {
  const { t } = useTranslation("BrowseListing");
  const filterKes = useSelector((state) => state.filter.filterKeys);
  const [alignment, setAlignment] = useState("grid");
  const [showFilterInMobile, setShowFilterInMobile] = useState(false); // show filter in mobile
  const handleShowFilterInMobile = () => {
    // handle show filter in mobile
    setShowFilterInMobile(!showFilterInMobile);
  };
  const handleChange = (event, newAlignment) => {
    // Only update if newAlignment is not null and different from current alignment
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  useEffect(() => {
    setShowFilterInMobile(false)
  }, [filterKes]);
  return (
    <>
      <Box sx={{ py: 8 }}>
        <Grid container sx={{ justifyContent: "space-around" }} spacing={2}>
          <Grid item xs={12} md={3} sx={{ display: ["none", "none", "block"] }}>
            <FilterContainer />
          </Grid>
          <Grid item xs={12} md={9}>

            <Container>
              <Search />
              <SelectedFilter />
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleShowFilterInMobile}
                  sx={{ display: ["block", "block", "none"], my: 3, py: 1 }}
                >
                  Filter
                </Button>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{ display: "flex", justifyContent: "end", my: 3, flexGrow: 1 }}
                >
                  <ToggleButton value="grid">
                    <WindowIcon color="primary" />
                    {t("switchView.grid")}
                  </ToggleButton>
                  <ToggleButton value="map">
                    <LocationOnIcon color="primary" />
                    {t("switchView.map")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Box sx={{ display: ["block", "block", "none"] }}>
                <CatigoryListOnMobile />
                {showFilterInMobile && (
                  <FilterContainerOnMobile />
                )}
              </Box>
              {alignment == "grid" ? <ProductsListContainer /> : <MapComponent />}
            </Container>
          </Grid>


        </Grid>
      </Box>
    </>
  );
};



