import { Box, Grid } from "@mui/material";
import UploadImages from "./uploading-image/UploadImages";
import { useAddProductValidation } from "../../validation/addProductValidation";
import { useTranslation } from "react-i18next";
import useProductForm from "./hook/useProductForm";
import BaseForm from "../../formik/BaseForm";
import SubmitButton from "../../formik/SubmitButton";
import InputFileds from "./InputFiled";
import ReadPrivacyConditions from "./ReadPrivacyConditions";
import ItemLocation from "./ItemLocation";

const AddProductForm = () => {
  const { t } = useTranslation("add-product");
  const { addProductValidationSchema } = useAddProductValidation();
  const { /* handleImageChange, imageLoading, */ onSubmit, loading } = useProductForm();

  const savedProduct = JSON.parse(localStorage.getItem("productSaved"));
  const initialValues = savedProduct ? savedProduct : {
    category: "",
    name: "",
    price: "",
    per: "",
    description: "",
    condition: "",
    pictures: [],
    location: null
  };

  return (
    <Box>
      <BaseForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={addProductValidationSchema}
      >

        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <InputFileds />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadImages />
            <ItemLocation />
            <ReadPrivacyConditions />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Box sx={{ width: "300px" }}>
              <SubmitButton disabled={loading}>
                {t("formFields.submit")}
              </SubmitButton>
            </Box>
          </Grid>
        </Grid>
      </BaseForm>
    </Box>
  );
};

export default AddProductForm;
