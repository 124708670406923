import { Box, Typography } from "@mui/material";
import { SelectLocation } from "../general/SelectLocation";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

const ItemLocation = () => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("add-product");
  const onLocationChange = (location) => {
    setFieldValue("location", location);
  }
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h6" sx={{ mb: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}>
        {t("formFields.location")}
      </Typography>
      <SelectLocation useNearbyLocation={true} onLocationChange={onLocationChange} location={values.location} />
    </Box>
  )
}

export default ItemLocation