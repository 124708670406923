import { Box } from "@mui/material";
import logo2 from "../../../assets/images/logo2.svg";
import { Link } from "react-router-dom";
const FooterLogo = () => (
  <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
    <Link to="/">
      <img src={logo2} alt="logo" loading="lazy" style={{ objectFit: "contain" }} />
    </Link>
  </Box>
);

export default FooterLogo;
