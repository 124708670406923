import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useEditProfileValidation = () => {
  const { t } = useTranslation("settings");
  const editProfileValidationSchema = Yup.object().shape({
    username: Yup.string().required(t("acount-info.formErrors.username.required")),
    displayName: Yup.string().required(t("acount-info.formErrors.displayName.required")),
    firstName: Yup.string().required(t("acount-info.formErrors.firstName.required")),
    lastName: Yup.string().required(t("acount-info.formErrors.lastName.required")),
    email: Yup.string().trim().email(t("acount-info.formErrors.email.invalid")).required(t("acount-info.formErrors.email.required")),
    phoneNumber: Yup.string().trim()
    .matches(
      /^\+[1-9]\d{1,14}$/, 
      t("acount-info.formErrors.phoneNumber.invalidFormat")
    )
    .required(t("acount-info.formErrors.phoneNumber.required"))
  });
  return { editProfileValidationSchema };
};
